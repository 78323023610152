<template>
  <div class="quan">

    <h2 style="margin-bottom:30px;">修改档案</h2>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
    
    >
      <el-row>
          <el-col :span="7">
              <el-form-item label="档案日期:"  prop="checktime">
                  <el-date-picker  v-model="ruleForm.checktime"  disabled type="date" placeholder="选择日期"></el-date-picker>
              </el-form-item>
          </el-col>
          <el-col :span="7">
              <el-form-item label="检查地点:"  prop="hospital">
                  <el-select v-model="ruleForm.hospital" placeholder="请选择" >
                      <el-option
                        v-for="item in options2"
                        :key="item.value"
                        :label="item.v"
                        :value="item.k">
                    </el-option>
               </el-select>
              </el-form-item>
          </el-col>
      </el-row>
     <el-row>
          <el-col :span="7">
              <el-form-item label="身高:"  prop="height">
                  <el-input v-model="ruleForm.height" placeholder="请输入"> <template slot="append">cm</template></el-input>
              </el-form-item>
          </el-col>
          <el-col :span="7">
              <el-form-item label="体重:"  prop="weight">
                  <el-input v-model="ruleForm.weight" placeholder="请输入"> <template slot="append">kg</template></el-input>
              </el-form-item>
          </el-col>
      </el-row>

        <el-row>
          <el-col :span="7">
              <h3>右眼</h3>
          </el-col>
          <el-col :span="6">
              <h3>左眼</h3>
          </el-col>
      </el-row>


      <el-row>
      
          <el-col :span="7">
              <el-form-item label="裸眼视力:"   prop="rev">
                 <el-input v-model="ruleForm.rev" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
              <el-col :span="7">
              <el-form-item  label=" " prop="lev">
                  <el-input v-model="ruleForm.lev" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
      </el-row>

      <el-row>
        
          <el-col :span="7">
              <el-form-item label="矫正视力:"  prop="rcv">
                  <el-input v-model="ruleForm.rcv" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
            <el-col :span="7">
              <el-form-item  prop="lcv">
                  <el-input v-model="ruleForm.lcv" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
      </el-row>
    <el-row>
        
          <el-col :span="7">
              <el-form-item label="小瞳屈光度:" prop="rs">
                  <el-input v-model="ruleForm.rs" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
            <el-col :span="7">
              <el-form-item   prop="ls">
                  <el-input v-model="ruleForm.ls" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
      </el-row>
       <el-row>
       
          <el-col :span="7">
              <el-form-item label="小瞳散光:"  prop="rc">
                  <el-input v-model="ruleForm.rc" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
             <el-col :span="7">
              <el-form-item  prop="lc">
                  <el-input v-model="ruleForm.lc" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
      </el-row>
       <el-row>
      
          <el-col :span="7">
              <el-form-item label="散瞳屈光度:" prop="srs">
                  <el-input v-model="ruleForm.srs" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
              <el-col :span="7">
              <el-form-item   prop="sls">
                  <el-input v-model="ruleForm.sls" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
      </el-row>
       <el-row>
         
          <el-col :span="7">
              <el-form-item label="散瞳散光:" prop="src">
                  <el-input v-model="ruleForm.src" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>

           <el-col :span="7">
              <el-form-item   prop="slc">
                  <el-input v-model="ruleForm.slc" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
      </el-row>
      <el-row>

<el-col :span="7">
  <el-form-item label="散光轴位:" prop="rc">
    <el-input v-model="ruleForm.rax" placeholder="请输入内容" input type="number"></el-input>
  </el-form-item>
</el-col>
<el-col :span="7">
  <el-form-item prop="lc">
    <el-input v-model="ruleForm.lax" placeholder="请输入内容" input type="number"></el-input>
  </el-form-item>
</el-col>
</el-row>
<el-row>
<el-col :span="7">
  <el-form-item label="复验球镜:" prop="rc">
    <el-input v-model="ruleForm.rfs" placeholder="请输入内容"></el-input>
  </el-form-item>
</el-col>
<el-col :span="7">
  <el-form-item prop="lc">
    <el-input v-model="ruleForm.lfs" placeholder="请输入内容"></el-input>
  </el-form-item>
</el-col>
</el-row>
<el-row>
<el-col :span="7">
  <el-form-item label="复验柱镜:" prop="rc">
    <el-input v-model="ruleForm.rfc" placeholder="请输入内容"></el-input>
  </el-form-item>
</el-col>
<el-col :span="7">
  <el-form-item prop="lc">
    <el-input v-model="ruleForm.lfc" placeholder="请输入内容"></el-input>
  </el-form-item>
</el-col>
</el-row>
       <el-row>
        
          <el-col :span="7">
              <el-form-item label="眼轴:"  prop="ra">
                  <el-input v-model="ruleForm.ra" placeholder="请输入内容" ></el-input>
              </el-form-item>
          </el-col>
           <el-col :span="7">
              <el-form-item  prop="la">
                  <el-input v-model="ruleForm.la" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
      </el-row>
       <el-row>
          
          <el-col :span="7">
              <el-form-item label="K1:" prop="rk1">
                  <el-input v-model="ruleForm.rk1" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
          <el-col :span="7">
              <el-form-item   prop="lk1">
                  <el-input v-model="ruleForm.lk1" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
      </el-row>
       <el-row>
         
          <el-col :span="7">
              <el-form-item label="K2:" prop="rk2">
                  <el-input v-model="ruleForm.rk2" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
           <el-col :span="7">
              <el-form-item   prop="lk2">
                  <el-input v-model="ruleForm.lk2" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
      </el-row>
       <el-row>
          
          <el-col :span="7">
              <el-form-item label="眼压:"  prop="ri">
                  <el-input v-model="ruleForm.ri" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
          <el-col :span="7">
              <el-form-item  prop="li">
                  <el-input v-model="ruleForm.li" placeholder="请输入内容"></el-input>
              </el-form-item>
          </el-col>
      </el-row>  

 <el-row>
      <el-form-item label="检测设备" prop="checkdev">
        <el-select v-model="ruleForm.checkdev" placeholder="请选择" >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      </el-row>
 <el-row>
        <el-form-item label="医学防控方案" prop="corrects">
          <el-select v-model="ruleForm.corrects" multiple placeholder="请选择" style="width: 36vw;" @change="change">
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
          </el-option>
        </el-select>
              
        </el-form-item>
</el-row>
<el-row>
        <el-form-item label="镜片品牌" prop="corrects">
          <el-select v-model="ruleForm.corrects2"  placeholder="请选择" style="width: 36vw;" @change="change" >
            <el-option
              v-for="item in options4"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
          </el-option>
         </el-select>
        </el-form-item>
      </el-row> 
<el-row>
        <el-col :span="7">
          <el-form-item label="瞳距:" prop="tj">
            <el-input v-model="ruleForm.ljpd" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="瞳高:" prop="tg">
            <el-input v-model="ruleForm.ljtg" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-col>
      </el-row> 
 <el-row>
    <el-col :span="13">
      <el-form-item label="备注" prop="remark">
        <el-input
          type="textarea"
          v-model="ruleForm.remark"
          maxlength="255"
        ></el-input>
      </el-form-item>
    </el-col> 
   </el-row> 
    <el-row style="margin-left:10px; width:60%;" >
      <h4 style="margin-top:10px;">上传照片：</h4>
        <el-upload
          action=""
          list-type="picture-card"
          :limit="12"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :file-list="ruleForm.imgpathShow"
          :http-request="uploadImgs">
          <i class="el-icon-plus"></i>
        </el-upload>
     </el-row>  
  
      <div >
          <el-image  :src="src" class="tu" v-if="tu"></el-image>
      </div>
  

<el-collapse accordion  class="sljc">
  <el-collapse-item >
    <template slot="title" >视功能检查记录
    </template>
        <div class="hang" >
            <h2>Worth</h2>
            <div class="middle">
              <div>同时视</div>
              <div>40cm</div>
            </div>
            <el-select v-model="tss" placeholder="请选择">
                <el-option
                v-for="item in optiont"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
         
            <div class="middle">
              <div>融合视</div>
              <div>20cm</div>
           </div>
            <el-select v-model="rhs" placeholder="请选择">
                <el-option
                v-for="item in optionr"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
         
           
            <div class="middle">
              <div>立体视</div>
              <div>5cm</div>
            </div>
            <el-select v-model="lts" placeholder="请选择">
                <el-option
                v-for="item in optioni"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
               
            
        </div>
    <el-divider></el-divider>

    <div class="hang">
                <div class="middle1">
                  <div>远用眼位</div>
                  <div>正常（B01-B13）</div>
                </div>
                <el-input v-model="yyyw" placeholder="请输入内容"></el-input>
                <div class="middle">
                  <div>调节反应</div>
                  <div>滞后/超前-1.00D-+2.00D）</div>
                </div>
                <el-input v-model="tjfy" placeholder="请输入内容"></el-input>
    </div>
    <el-divider></el-divider>

    <div class="hang">
                <div class="middle1">
                  <div>近用眼位</div>
                  <div>正常（0-B16）</div>
                </div>
                <el-input v-model="jyyw" placeholder="请输入内容"></el-input>
                <div class="middle">
                  <div>阶梯性AC/A</div>
                  <div>正常（2-5）</div>
                </div>
               <el-input v-model="jtxac" placeholder="请输入内容"></el-input>
    </div>
    <el-divider></el-divider>
        <div class="hang">
                <div class="middle1">
                  <div>NRA</div>
                  <div>正常（+1.50-+2.50D）</div>
                </div>
                <el-input v-model="nra" placeholder="请输入内容"></el-input>
                <div class="middle">
                  <div>PRA</div>
                  <div>正常（-1.25--3.25D）</div>
                </div>
                <el-input v-model="pra" placeholder="请输入内容"></el-input>
    </div>
    <el-divider></el-divider>
        <div class="hang">
                <div class="middle1" >
                  <div>AMP</div>
                  <div>正常（15-0.25*年龄）</div>
                </div>
                <div>R</div>
                <el-input v-model="ramp" placeholder="请输入内容" style="margin: 0 10px;"></el-input>
                 <div>L</div>
                <el-input v-model="lamp" placeholder="请输入内容" style="margin: 0 10px;"></el-input>
                 <div>双眼</div>
                <el-input v-model="samp" placeholder="请输入内容" style="margin: 0 10px;"></el-input>
        </div>
    <el-divider></el-divider>

   <div class="hang">
                <div class="middle1" >
                  <div>Flippers</div>
                  <div class="kk">正常单眼11分次/分钟</div>
                  <div>双眼8次/分钟，+-2.00D</div>
                </div>
                 <div class="middle2" style="margin-left:35px">
                  <div>R正负片</div>
                  <div class="kk">L正负片</div>
                  <div style="width: 80PX;">双眼正负片</div>
                </div>
                <div class="middle3">
                    <el-select v-model="rzfp" placeholder="请选择">
                      <el-option
                      v-for="item in options5"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                      </el-option>
                    </el-select>
                    <el-select v-model="lzfp" placeholder="请选择">
                      <el-option
                      v-for="item in options6"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                      </el-option>
                    </el-select>
                    <el-select v-model="szfp" placeholder="请选择">
                        <el-option
                        v-for="item in options7"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div style="margin-left:35px">
                  <div>次数</div>
                  <div class="kk">次数</div>
                  <div>次数</div>
                </div>
                 <div class="middle3" >
                   <el-input v-model="rzfpnum" placeholder="请输入内容" style="margin: 0 10px;"></el-input>
                   <el-input v-model="lzfpnum" placeholder="请输入内容" style="margin: 0 10px;"></el-input>
                   <el-input v-model="szfpnum" placeholder="请输入内容" style="margin: 0 10px;"></el-input>
                </div>
                
        </div>
  </el-collapse-item>
</el-collapse>

  <el-divider></el-divider>

        <div class="foot">
            <el-button  @click="qx()">取消</el-button >
            <el-button type="primary"  @click="submitForm('ruleForm')">确认修改</el-button >
        </div>
    
    </el-form>
     
  </div>
</template>

<script>
import { timestampToTime } from "./formdate";
import OSS from "ali-oss";
const client = new OSS({ 
    region: "oss-cn-beijing",
    accessKeyId: "LTAIuIJwjQDNloUw",
    accessKeySecret: "GohgvAzqsYA7pTWeceXcftLVy9TMPH",
    bucket: "bwdev"
});
export default {
  data() {
    return {
      tishi: [],
      tu:false,
      id:"",
      kiid:"",
      ruleForm: {
        checktime: "",
        hospital: "",
        height:"",
        weight:"",
        lev:"",
        rev:"",
        lcv:"",
        rcv:"",
        ls:"",
        rs:"",
        sls:"",
        srs:"",
        lc:"",
        rc:"",
        slc:"",
        src:"",   
        la:"",
        ra:"",
        lk1:"",
        rk1:"",
        lk2:"",
        rk2:"",
        li:"",
        ri:"",
        checkdev:"",
        corrects:[],
        remark:"",
        imgpaths:[],
        imgpathShow:[],
        ljtg: "",
        ljpd: "",
        lax: "",
        rax: "",
        lfs: "",
        rfs: "",
        lfc: "",
        rfc: "",
        corrects2:[]
      },
      rules: {
        checktime: [{ required: true, message: "请选择设立档案日期", trigger: "blur" }],
        hospital: [{ required: true, message: "请选择检查地点", trigger: "blur" }],
        height: [{ required: true, message: "请输入身高", trigger: "blur" }],
        weight: [{ required: true, message: "请输入体重", trigger: "blur" }],
        lev: [{ required: true, message: "请左眼裸眼视力", trigger: "blur" }],
        rev: [{ required: true, message: "请右眼裸眼视力", trigger: "blur" }],
        // checkdev: [{ required: true, message: "请选择检查设备", trigger: "blur" }],
  
      },
      options:[
        {
          value: '-1',
          label: '无'
        },
      ],
      options2:[],
      options3:[],
      options4:[
      {label:"未戴镜" ,value:"未戴镜"},
      {label:"普通单光镜" ,value:"普通单光镜"},
      {label:"星乐视" ,value:"星乐视"},
      {label:"星趣控" ,value:"星趣控"},
      {label:"新乐学" ,value:"新乐学"},
      {label:"成长乐" ,value:"成长乐"},
      {label:"五分眼选" ,value:"五分眼选"},
      {label:"其他框架镜" ,value:"其他框架镜"},
      {label:"思问子夏" ,value:"思问子夏"},
      {label:"思问子贡" ,value:"思问子贡"},
      {label:"小乐园" ,value:"小乐园"},
      {label:"5度眼镜" ,value:"5度眼镜"}],
      src: '',
      tss:0,
      rhs:0,
      lts:0,
      optiont:[{
          value: 1,
          label: '空值'
        }, {
          value: 2,
          label: '正常'
        }, {
          value: 3,
          label: '复视'
        }, {
          value: 4,
          label: '单眼单视'
        }, {
          value: 5,
          label: '融合视'
        }],
      optionr:[{
          value: 1,
          label: '正常'
        }, {
          value: 2,
          label: '异常'
        }],
      optioni:[{
          value: 1,
          label: '空值'
        }, {
          value: 2,
          label: '正常'
        }, {
          value: 3,
          label: '异常'
        }],
         yyyw:"",
         tjfy:"",
         jyyw:"",
         jtxac:0,
         nra:"",
         pra:"",
         lamp:"",
         ramp:"",
         samp:"",
         rzfp:0,
         lzfp:0,
         szfp:0,
         options5:[{
          value: 1,
          label: '空值'
        }, {
          value: 2,
          label: '正常'
        }, {
          value: 3,
          label: '正片慢'
        }, {
          value: 4,
          label: '负片慢'
        }],
          options6:[{
          value: 1,
          label: '空值'
        }, {
          value: 2,
          label: '正常'
        }, {
          value: 3,
          label: '正片慢'
        }, {
          value: 4,
          label: '负片慢'
        }],
         options7:[{
          value: 1,
          label: '空值'
        }, {
          value: 2,
          label: '正常'
        }, {
          value: 3,
          label: '正片慢'
        }, {
          value: 4,
          label: '负片慢'
        }],
         rzfpnum:0,
         lzfpnum:0,
         szfpnum:0,
         yj_address:"yj_address",
    };
  },

  methods: {
     handleRemove(file,fileList) {
       console.log("filelist=>",fileList);

        let ss=[];
        for(var i = 0 ; i < fileList.length; i++){
                ss.push(fileList[i].url)
                  }
                  this.ruleForm.imgpaths=ss;
                  console.log("imgpaths=>",this.ruleForm.imgpaths);
                  console.log("ss",ss);
      },
      handlePictureCardPreview(file) {
        this.src = file.url;
        this.tu=true;
      },
      // 获取设备
      checkdev(){
          this.$api.vipuser.getDev({
               })
              .then((res) => {
                for (let index = 0; index < res.obj.length; index++) {
                    this.options.push(res.obj[index]);         
                }
                // console.log(this.options);
              })
              .catch((error) => {
                console.log(error);
              });
      },
      //获取地址
        getinfo(){
      this.$api.vipuser.getinfo({
          dtype: this.yj_address
      })
        .then((res) => {
          console.log(res);
          console.log('getinfo');
          this.options2=res.obj;
        })
        .catch((error) => {
          console.log(error);
        });
      },

 //获取医学防控方案
        fkList(){
      this.$api.vipuser.fkList()
        .then((res) => {
          console.log('获取医学防控方案=>',res);
          this.options3=res.obj;
        })
        .catch((error) => {
          console.log(error);
        });
      },

      qx(){
            this.$router.go(-1); 
      },


      submitForm(formName){
        console.log("submitFormimgpaths",this.ruleForm.imgpaths.toString());
    this.$refs[formName].validate((valid) => {
            if (valid) {
       
              if (
                         ((this.ruleForm.ls!=""&&this.ruleForm.rs!="")||(this.ruleForm.sls!=""&&this.ruleForm.srs!=""))
                    && ((this.ruleForm.lc!=""&&this.ruleForm.rc!="")||(this.ruleForm.slc!=""&&this.ruleForm.src!=""))
                    ) {
                      // if ((this.tishi.length != 0 || this.ruleForm.corrects.length == 0) || this.ruleForm.corrects.length != 0) {
                        if (((this.ruleForm.ljpd == "" && this.ruleForm.ljtg == "") || (this.ruleForm.ljpd == "" || this.ruleForm.ljtg == "")) && (this.ruleForm.corrects2.length!= 0)) {
                console.log(222);
                this.$notify({
                  title: '警告',
                  message: "请输入瞳距，瞳高",
                  type: 'error'
                });
              } else {
                        console.info('kiid',this.kiid)
                            this.$api.vipuser.update({
                              checktime:timestampToTime(this.ruleForm.checktime) ,
                              hospital: this.ruleForm.hospital,
                              checkdev: this.ruleForm.checkdev,
                              height: this.ruleForm.height,
                              weight: this.ruleForm.weight,
                              lev: this.ruleForm.lev,
                              rev: this.ruleForm.rev,
                              lcv: this.ruleForm.lcv,
                              rcv: this.ruleForm.rcv,
                              ls: this.ruleForm.ls,
                              rs: this.ruleForm.rs,
                              sls: this.ruleForm.sls,
                              srs: this.ruleForm.srs,
                              lc: this.ruleForm.lc,
                              rc: this.ruleForm.rc,
                              slc: this.ruleForm.slc,
                              src: this.ruleForm.src,
                              la: this.ruleForm.la,
                              ra: this.ruleForm.ra,
                              lk1: this.ruleForm.lk1,
                              lk2: this.ruleForm.lk2,
                              rk1: this.ruleForm.rk1,
                              rk2: this.ruleForm.rk2,
                              li: this.ruleForm.li,
                              ri: this.ruleForm.ri,
                              corrects: this.ruleForm.corrects.toString(),
                              imgpaths: this.ruleForm.imgpaths.toString(),
                              remark: this.ruleForm.remark,
                              tss: this.tss,
                              rhs: this.rhs,
                              lts: this.lts,
                              yyyw: this.yyyw,
                              tjfy: this.tjfy,
                              jyyw: this.jyyw,
                              jtxac: this.jtxac,
                              nra: this.nra,
                              pra: this.pra,
                              lamp: this.lamp,
                              ramp: this.ramp,
                              samp: this.samp,
                              rzfp: this.rzfp,
                              lzfp: this.lzfp,
                              szfp: this.szfp,
                              rzfpnum: this.rzfpnum,
                              lzfpnum: this.lzfpnum,
                              szfpnum: this.szfpnum,
                              kiid: this.kiid,
                              id:this.id,
                              ljpd: this.ruleForm.ljpd,
                              ljtg: this.ruleForm.ljtg,
                              lax: this.ruleForm.lax,
                              rax: this.ruleForm.rax,
                              lfs: this.ruleForm.lfs,
                              rfs: this.ruleForm.rfs,
                              lfc: this.ruleForm.lfc,
                              rfc: this.ruleForm.rfc,
                              ljpt:this.ruleForm.corrects2.toString(),
                          })
                            .then((res) => {
                              console.log(res);
                               console.log("oldsubmitForm");
                            if (res.status!=0) {
                              this.$notify({
                                      title: '成功',
                                      message: '添加成功',
                                      type: 'success'
                                    });
                
                            this.$router.push({
                                path: "./vipinfo",
                                query: { kiid : this.kiid},});
                            } else {
                              this.$notify({
                                      title: '警告',
                                      message: res.msg,
                                      type: 'warning'
                                    });
                            }
                          
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                          }
                        } else {
                                      this.$notify({
                                          title: "警告",
                                          message: "小瞳屈光度或散瞳屈光度二选一必填,小瞳散光或散瞳散光二选一必填",
                                          type: "warning",
                                          duration:30000
                                        });
                        }

                      } else {
                          this.$notify({
                                    title: '警告',
                                    message: res.msg,
                                    type: 'warning'
                                  });
                      }
                    });
      },
      uploadImgs(files) {
          const that = this;
          let fileArray = [];
          if (Array.isArray(files)) {
              fileArray.push(...files);
          } else {
              fileArray.push(files);
          }

          let put = async file => {
              // try {
                  let date = new Date();
                  let yaer = date.getFullYear().toString();
                  let month = (date.getMonth() + 1).toString();
                  let day = date.getDate().toString();
                  let hour = date.getDate().toString();
                  let min = date.getMinutes().toString();
                  let seconds = date.getSeconds().toString();
                  let tmp = yaer + month + day + hour + min + seconds + "/" + file.file.name;
                  console.info(tmp);
                  let result = await client.put(tmp, file.file);
                  if (result.res.status == 200) {
                      that.ruleForm.imgpaths.push(result.url);
                      console.info( that.ruleForm.imgpaths );
                  }
              // } catch (error) {
              //   console.error(error)
              //   this.$notify({
              //     title: '警告',
              //     message: '上传失败',
              //     type: 'warning'
              //   });
              // }
          };
          fileArray.forEach(file => {
            console.log("file.file.name",file.file.name);
              put(file);
          });
      },

     postlist(){
       var tid=this.id
       console.log(tid);
                this.$api.vipuser.tinfo({id:tid})
                .then((res) => {
                  console.log("postlist=>", res);
                   this.ruleForm.checktime=res.obj.checktime ;
                   this.ruleForm.hospital=res.obj.hospital;
                   this.ruleForm.checkdev=res.obj.checkdev;
                   this.ruleForm.height=res.obj.height;
                   this.ruleForm.weight=res.obj.weight;
                   this.ruleForm.lev=res.obj.lev;
                   this.ruleForm.rev=res.obj.rev;
                   this.ruleForm.lcv=res.obj.lcv;
                   this.ruleForm.rcv=res.obj.rcv;
                   this.ruleForm.ljpd = res.obj.ljpd;
                  this.ruleForm.ljtg = res.obj.ljtg;
                  this.ruleForm.lax = res.obj.lax;
                  this.ruleForm.rax = res.obj.rax;
                  this.ruleForm.lfs = res.obj.lfs;
                  this.ruleForm.rfs = res.obj.rfs;
                  this.ruleForm.lfc = res.obj.lfc;
                  this.ruleForm.rfc = res.obj.rfc;
                  // if (
                  //    ((res.obj.ls==res.obj.sls)&&(res.obj.rs==res.obj.srs))
                  //   && ((res.obj.rc==res.obj.src)&&(res.obj.lc==res.obj.slc))
                  // ) {
                  //  this.ruleForm.ls="";
                  //  this.ruleForm.rs="";
                  //  this.ruleForm.lc="";
                  //  this.ruleForm.rc="";
                  //  this.ruleForm.sls=res.obj.ls;
                  //  this.ruleForm.srs=res.obj.rs;
                  //  this.ruleForm.slc=res.obj.lc;
                  //  this.ruleForm.src=res.obj.rc;
                                
                  // } else {
                   this.ruleForm.ls=res.obj.ls;
                   this.ruleForm.rs=res.obj.rs;
                   this.ruleForm.sls=res.obj.sls;
                   this.ruleForm.srs=res.obj.srs;
                   this.ruleForm.lc=res.obj.lc;
                   this.ruleForm.rc=res.obj.rc;
                   this.ruleForm.slc=res.obj.slc;
                   this.ruleForm.src=res.obj.src;
                  // }

                  
                   this.ruleForm.la=res.obj.la;
                   this.ruleForm.ra=res.obj.ra;
                   this.ruleForm.lk1=res.obj.lk1;
                   this.ruleForm.lk2=res.obj.lk2;
                   this.ruleForm.rk1=res.obj.rk1;
                   this.ruleForm.rk2=res.obj.rk2;
                   this.ruleForm.li=res.obj.li;
                   this.ruleForm.ri=res.obj.ri;
                   if (res.obj.corrects) {
                      this.ruleForm.corrects=res.obj.corrects.split(',');
                   } else {
                     this.ruleForm.corrects="";
                   }
                  
                   if (res.obj.ljpt) {
                      this.ruleForm.corrects2=res.obj.ljpt.split(',');
                   } else {
                     this.ruleForm.corrects2="";
                   }
                  
                   this.ruleForm.remark=res.obj.remark;
                   this.tss=res.obj.tss;
                   this.rhs=res.obj.rhs;
                   this.lts=res.obj.lts;
                   this.yyyw=res.obj.yyyw;
                   this.tjfy=res.obj.tjfy;
                   this.jyyw=res.obj.jyyw;
                   this.jtxac=res.obj.jtxac;
                   this.nra=res.obj.nra;
                   this.pra=res.obj.pra;
                   this.lamp=res.obj.lamp;
                   this.ramp=res.obj.ramp;
                   this.samp=res.obj.samp;
                   this.rzfp=res.obj.rzfp;
                   this.lzfp=res.obj.lzfp;
                   this.szfp=res.obj.szfp;
                   this.rzfpnum=res.obj.rzfpnum;
                   this.lzfpnum=res.obj.lzfpnum;
                   this.szfpnum=res.obj.szfpnum;
                   this.kiid=res.obj.kiid;
                   var imgArr = res.obj.imgpaths.split(',');
                   console.log("imgArr.length",imgArr.length);
                   for(var i = 0 ; i < imgArr.length; i++){
                      var str = imgArr[i];
                      this.ruleForm.imgpathShow.push({
                        url: str,
                        name: Math.ceil(Math.random()*1000) + str.split('/')[4] 
                      })
                   }
                   this.ruleForm.imgpaths = res.obj.imgpaths.split(',');

                })
                .catch((error) => {
                  console.log(error);
                });
      },
      // maxImg(){
      //   this.$notify({
      //     title: "警告",
      //     message: "最多上传12张照片",
      //     type: "warning",
      //     duration:10000 * 10 
      //   });
      // }
      change() {
      // this.tishi = []

      // for (var i = 0; i < this.ruleForm.corrects.length; i++) {
      //   if ((this.ruleForm.corrects[i] == "60" || this.ruleForm.corrects[i] == "59" ||
      //     this.ruleForm.corrects[i] == "15" || this.ruleForm.corrects[i] == "17" ||
      //     this.ruleForm.corrects[i] == "18" || this.ruleForm.corrects[i] == "61" ||
      //     this.ruleForm.corrects[i] == "16" || this.ruleForm.corrects[i] == "19" ||
      //     this.ruleForm.corrects[i] == "20" || this.ruleForm.corrects[i] == "62")) {
      //     this.tishi.push(this.ruleForm.corrects[i])
      //   }
      // }

   console.log(this.ruleForm.corrects2);
    },
  },

     
    created() {
      this.kiid = this.$route.query.kiid;
      this.id = this.$route.query.id;
      console.info('created->id',this.id);
      console.info('created->kiid',this.kiid);
      this.checkdev();
      this.getinfo();
      this.fkList();
      this.postlist();
    },
};
</script>

<style scoped>
* {
  /* margin: 0; */
  padding: 0;
  box-sizing: border-box;
}

.hang {
  padding-top: 1vw;
  display: flex;
  /* justify-content:space-between; */
  align-items: center;
  padding: 0 50px;
  background: #eee;
}
h3 {
  margin-top: 20px;
 text-align: center;
}

h2{
  text-align: center;
}
.middle1{
 font-size:14px;
  width:150px;
}

.middle2{
 font-size:14px;
  width:60px;
}

.middle{
  margin-left:110px;
  font-size:14px;
  width:150px;
}

.middle3{
  margin-left:35px;
 font-size:14px;
  width:150px;
}

.el-input {
  width: 15vw;
}

.quan {
   padding: 1vw;
   overflow-y: auto !important;
   z-index: 2;
   position: relative;
}

.el-form-item {
    margin-top: 1px !important;
     margin-top: 14px;
}


.tu{
  position: absolute;
  top:80px;
  right:2vw;
  width:32vw;
}

.el-divider--horizontal{
   padding: 20px 0;
   background: #eee;
}

/deep/.el-collapse-item__header{
   margin-bottom: 15px;
   text-align: center;
   font-size: 16px;
   padding-left: 37vw;
}
/deep/.el-collapse-item__content{
 padding-bottom:0px ; 
}

.kk{
  margin: 12px 0;
}

.foot{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-button{
    margin: 10px;
  padding: 12px;
}

.el-divider--horizontal[data-v-d302e9a4]{
  margin:0;
  font-size:14px;
}

/deep/.el-select-dropdown__item{
  padding-left: 30px;
}

.sljc{
margin-top:20px; 
padding-left:10px;  
background: #eee;
}
</style>